import type { FC, PropsWithChildren } from "react";
import { useMemo } from "react";

import type { Colors } from "../utils/colors";
import type {
  FontFamily,
  FontSize,
  FontTextType,
  FontTransform,
  FontWeight,
} from "../utils/fonts";

export type TextProps = {
  className?: string;
  color?: Colors;
  family?: FontFamily;
  size?: FontSize;
  variant?: FontTextType;
  weight?: FontWeight;
  transform?: FontTransform;
};

const getTextClassNameByVariant = (
  variant: TextProps["variant"],
  family: TextProps["family"],
  size: TextProps["size"],
  weight: TextProps["weight"],
  color: TextProps["color"] & string,
  className: TextProps["className"],
  transform?: TextProps["transform"] & string,
) => {
  const basicClassNames = [
    family ? `font-${family}` : "font-sans",
    color ? `text-${color}` : "text-inherit",
    transform ?? "",
  ];
  switch (variant) {
    case "h1":
      return [
        ...basicClassNames,
        size ? `text-${size}` : "text-5xl",
        weight ? `font-${weight}` : "font-bold",
        className,
      ];
    case "h2":
      return [
        ...basicClassNames,
        size ? `text-${size}` : "text-4xl",
        weight ? `font-${weight}` : "font-bold",
        className,
      ];
    case "h3":
      return [
        ...basicClassNames,
        size ? `text-${size}` : "text-3xl",
        weight ? `font-${weight}` : "font-bold",
        className,
      ];
    case "h4":
      return [
        ...basicClassNames,
        size ? `text-${size}` : "text-2xl",
        weight ? `font-${weight}` : "font-normal",
        className,
      ];
    case "h5":
      return [
        ...basicClassNames,
        size ? `text-${size}` : "text-xl",
        weight ? `font-${weight}` : "font-normal",
        className,
      ];
    case "h6":
      return [
        ...basicClassNames,
        size ? `text-${size}` : "text-lg",
        weight ? `font-${weight}` : "font-normal",
        className,
      ];
    case "p":
    case "span":
    default:
      return [
        ...basicClassNames,
        size ? `text-${size}` : "text-sm sm:text-base",
        weight ? `font-${weight}` : "font-normal",
        className,
      ];
  }
};

const Text: FC<PropsWithChildren<TextProps>> = ({
  variant: Variant = "span",
  color = "inherit",
  family,
  size,
  weight,
  transform,
  children,
  className,
}) => {
  const generatedClassName = useMemo(
    () =>
      getTextClassNameByVariant(
        Variant,
        family,
        size,
        weight,
        color,
        className,
        transform,
      ).join(" "),
    [Variant, family, size, weight, color, className],
  );

  return <Variant className={generatedClassName}>{children}</Variant>;
};

export default Text;
