"use client";
import GenericIcon from "@whitelabel-engine/ui/Icon/GenericIcon";
import type { IconProps } from "@whitelabel-engine/ui/Icon/utils";
import Image from "@whitelabel-engine/ui/Image";
import dynamic from "next/dynamic";
import type { FC } from "react";
import { memo, useEffect, useState } from "react";

import { generateHash } from "../utils/hash";

export type IconRenderProps = IconProps & {
  height?: number;
  alt?: string;
  icon:
    | string
    | File
    | {
        viewBox: string;
        paths: string[];
      };
};

const fetchLazyLoadedIcon = (name: string) => {
  return dynamic(() => {
    switch (name) {
      case "Add":
      case "add":
        return import("@whitelabel-engine/ui/Icon/Add");
      case "Alert":
      case "alert":
        return import("@whitelabel-engine/ui/Icon/Alert");
      case "ArrowLocator":
      case "arrowlocator":
        return import("@whitelabel-engine/ui/Icon/ArrowLocator");
      case "ArrowOutlineDown":
      case "arrowoutlinedown":
        return import("@whitelabel-engine/ui/Icon/ArrowOutlineDown");
      case "ArrowOutlineLeft":
      case "arrowoutlineleft":
        return import("@whitelabel-engine/ui/Icon/ArrowOutlineLeft");
      case "ArrowOutlineRight":
      case "arrowoutlineright":
        return import("@whitelabel-engine/ui/Icon/ArrowOutlineRight");
      case "ArrowOutlineUp":
      case "arrowoutlineup":
        return import("@whitelabel-engine/ui/Icon/ArrowOutlineUp");
      case "ArrowSquaredRight":
      case "arrowsquaredright":
        return import("@whitelabel-engine/ui/Icon/ArrowSquaredRight");
      case "Avalanche":
      case "avalanche":
        return import("@whitelabel-engine/ui/Icon/Avalanche");
      case "Back":
      case "back":
        return import("@whitelabel-engine/ui/Icon/Back");
      case "Bondly":
      case "bondly":
        return import("@whitelabel-engine/ui/Icon/Bondly");
      case "Bridge":
      case "bridge":
        return import("@whitelabel-engine/ui/Icon/Bridge");
      case "Burn":
      case "burn":
        return import("@whitelabel-engine/ui/Icon/Burn");
      case "BurnFilled":
      case "burnfilled":
        return import("@whitelabel-engine/ui/Icon/BurnFilled");
      case "BurnSwap":
      case "burnswap":
        return import("@whitelabel-engine/ui/Icon/BurnSwap");
      case "Check":
      case "check":
        return import("@whitelabel-engine/ui/Icon/Check");
      case "CheckboxFilled":
      case "checkboxfilled":
        return import("@whitelabel-engine/ui/Icon/CheckboxFilled");
      case "CheckboxTick":
      case "checkboxtick":
        return import("@whitelabel-engine/ui/Icon/CheckboxTick");
      case "CheckCircle":
      case "checkcircle":
        return import("@whitelabel-engine/ui/Icon/CheckCircle");
      case "Clock":
      case "clock":
        return import("@whitelabel-engine/ui/Icon/Clock");
      case "Close":
      case "close":
        return import("@whitelabel-engine/ui/Icon/Close");
      case "Cold":
      case "cold":
        return import("@whitelabel-engine/ui/Icon/Cold");
      case "Community":
      case "community":
        return import("@whitelabel-engine/ui/Icon/Community");
      case "CoinMarketCap":
      case "coinmarketcap":
        return import("@whitelabel-engine/ui/Icon/CoinMarketCap");
      case "Connect":
      case "connect":
        return import("@whitelabel-engine/ui/Icon/Connect");
      case "Copy":
      case "copy":
        return import("@whitelabel-engine/ui/Icon/Copy");
      case "Discord":
      case "discord":
        return import("@whitelabel-engine/ui/Icon/Discord");
      case "Database":
      case "database":
        return import("@whitelabel-engine/ui/Icon/Database");
      case "Dollar":
      case "dollar":
        return import("@whitelabel-engine/ui/Icon/Dollar");
      case "DownCircle":
      case "downcircle":
        return import("@whitelabel-engine/ui/Icon/DownCircle");
      case "Edit":
      case "edit":
        return import("@whitelabel-engine/ui/Icon/Edit");
      case "Email":
      case "email":
        return import("@whitelabel-engine/ui/Icon/Email");
      case "Ethereum":
      case "ethereum":
        return import("@whitelabel-engine/ui/Icon/Ethereum");
      case "Facebook":
      case "facebook":
        return import("@whitelabel-engine/ui/Icon/Facebook");
      case "File":
      case "file":
        return import("@whitelabel-engine/ui/Icon/File");
      case "Filter":
      case "filter":
        return import("@whitelabel-engine/ui/Icon/Filter");
      case "Folder":
      case "folder":
        return import("@whitelabel-engine/ui/Icon/Folder");
      case "Funnel":
      case "funnel":
        return import("@whitelabel-engine/ui/Icon/Funnel");
      case "Gift":
      case "gift":
        return import("@whitelabel-engine/ui/Icon/Gift");
      case "Github":
      case "github":
        return import("@whitelabel-engine/ui/Icon/Github");
      case "Hide":
      case "hide":
        return import("@whitelabel-engine/ui/Icon/Hide");
      case "Info":
      case "info":
        return import("@whitelabel-engine/ui/Icon/Info");
      case "InfoFilled":
      case "infofilled":
        return import("@whitelabel-engine/ui/Icon/InfoFilled");
      case "Instagram":
      case "instagram":
        return import("@whitelabel-engine/ui/Icon/Instagram");
      case "Key":
      case "key":
        return import("@whitelabel-engine/ui/Icon/Key");
      case "Launchpad":
      case "launchpad":
        return import("@whitelabel-engine/ui/Icon/Launchpad");
      case "LeftCircle":
      case "leftcircle":
        return import("@whitelabel-engine/ui/Icon/LeftCircle");
      case "Link":
      case "link":
        return import("@whitelabel-engine/ui/Icon/Link");
      case "LinkConnect":
      case "linkconnect":
        return import("@whitelabel-engine/ui/Icon/LinkConnect");
      case "Linkedin":
      case "linkedin":
        return import("@whitelabel-engine/ui/Icon/Linkedin");
      case "List":
      case "list":
        return import("@whitelabel-engine/ui/Icon/List");
      case "Loading":
      case "loading":
        return import("@whitelabel-engine/ui/Icon/Loading");
      case "Location":
      case "location":
        return import("@whitelabel-engine/ui/Icon/Location");
      case "Lock":
      case "lock":
        return import("@whitelabel-engine/ui/Icon/Lock");
      case "Logout":
      case "logout":
        return import("@whitelabel-engine/ui/Icon/Logout");
      case "Market":
      case "market":
        return import("@whitelabel-engine/ui/Icon/Market");
      case "Matic":
      case "matic":
        return import("@whitelabel-engine/ui/Icon/Matic");
      case "Medium":
      case "medium":
        return import("@whitelabel-engine/ui/Icon/Medium");
      case "Megaphone":
      case "megaphone":
        return import("@whitelabel-engine/ui/Icon/Megaphone");
      case "Mentorship":
      case "mentorship":
        return import("@whitelabel-engine/ui/Icon/Mentorship");
      case "Menu":
      case "menu":
        return import("@whitelabel-engine/ui/Icon/Menu");
      case "MenuTilted":
      case "menutilted":
        return import("@whitelabel-engine/ui/Icon/MenuTilted");
      case "Metamask":
      case "metamask":
        return import("@whitelabel-engine/ui/Icon/Metamask");
      case "Minus":
      case "minus":
        return import("@whitelabel-engine/ui/Icon/Minus");
      case "Missing":
      case "missing":
        return import("@whitelabel-engine/ui/Icon/Missing");
      case "More":
      case "more":
        return import("@whitelabel-engine/ui/Icon/More");
      case "Notify":
      case "notify":
        return import("@whitelabel-engine/ui/Icon/Notify");
      case "NotifyActive":
      case "notifyactive":
        return import("@whitelabel-engine/ui/Icon/NotifyActive");
      case "OpenSea":
      case "opensea":
        return import("@whitelabel-engine/ui/Icon/OpenSea");
      case "Plus":
      case "plus":
        return import("@whitelabel-engine/ui/Icon/Plus");
      case "PlusThin":
      case "plusthin":
        return import("@whitelabel-engine/ui/Icon/PlusThin");
      case "Profile":
      case "profile":
        return import("@whitelabel-engine/ui/Icon/Profile");
      case "QuoteDown":
      case "quotedown":
        return import("@whitelabel-engine/ui/Icon/QuoteDown");
      case "Refresh":
      case "refresh":
        return import("@whitelabel-engine/ui/Icon/Refresh");
      case "RightCircle":
      case "rightcircle":
        return import("@whitelabel-engine/ui/Icon/RightCircle");
      case "Save":
      case "save":
        return import("@whitelabel-engine/ui/Icon/Save");
      case "Saved":
      case "saved":
        return import("@whitelabel-engine/ui/Icon/Saved");
      case "Search":
      case "search":
        return import("@whitelabel-engine/ui/Icon/Search");
      case "Settings":
      case "settings":
        return import("@whitelabel-engine/ui/Icon/Settings");
      case "Show":
      case "show":
        return import("@whitelabel-engine/ui/Icon/Show");
      case "Smallcaps":
      case "smallcaps":
        return import("@whitelabel-engine/ui/Icon/Smallcaps");
      case "Swap":
      case "swap":
        return import("@whitelabel-engine/ui/Icon/Swap");
      case "Task":
      case "task":
        return import("@whitelabel-engine/ui/Icon/Task");
      case "Telegram":
      case "telegram":
        return import("@whitelabel-engine/ui/Icon/Telegram");
      case "Text":
      case "text":
        return import("@whitelabel-engine/ui/Icon/Text");
      case "TextAlignCenter":
      case "textaligncenter":
        return import("@whitelabel-engine/ui/Icon/TextAlignCenter");
      case "TextAlignJustifyLeft":
      case "textalignjustifyleft":
        return import("@whitelabel-engine/ui/Icon/TextAlignJustifyLeft");
      case "TextAlignJustifyRight":
      case "textalignjustifyright":
        return import("@whitelabel-engine/ui/Icon/TextAlignJustifyRight");
      case "TextAlignLeft":
      case "textalignleft":
        return import("@whitelabel-engine/ui/Icon/TextAlignLeft");
      case "TextAlignRight":
      case "textalignright":
        return import("@whitelabel-engine/ui/Icon/TextAlignRight");
      case "TextBold":
      case "textbold":
        return import("@whitelabel-engine/ui/Icon/TextBold");
      case "TextItalic":
      case "textitalic":
        return import("@whitelabel-engine/ui/Icon/TextItalic");
      case "TextUnderline":
      case "textunderline":
        return import("@whitelabel-engine/ui/Icon/TextUnderline");
      case "Tiktok":
      case "tiktok":
        return import("@whitelabel-engine/ui/Icon/Tiktok");
      case "Timeline":
      case "timeline":
        return import("@whitelabel-engine/ui/Icon/Timeline");
      case "Timer":
      case "timer":
        return import("@whitelabel-engine/ui/Icon/Timer");
      case "Trash":
      case "trash":
        return import("@whitelabel-engine/ui/Icon/Trash");
      case "Tutorial":
      case "tutorial":
        return import("@whitelabel-engine/ui/Icon/Tutorial");
      case "X":
      case "x":
      case "Twitter":
      case "twitter":
        return import("@whitelabel-engine/ui/Icon/X");
      case "Unlock":
      case "unlock":
        return import("@whitelabel-engine/ui/Icon/Unlock");
      case "UpCircle":
      case "upcircle":
        return import("@whitelabel-engine/ui/Icon/UpCircle");
      case "User":
      case "user":
        return import("@whitelabel-engine/ui/Icon/User");
      case "UserEdit":
      case "useredit":
        return import("@whitelabel-engine/ui/Icon/UserEdit");
      case "UserSearch":
      case "usersearch":
        return import("@whitelabel-engine/ui/Icon/UserSearch");
      case "Venly":
      case "venly":
        return import("@whitelabel-engine/ui/Icon/Venly");
      case "Video":
      case "video":
        return import("@whitelabel-engine/ui/Icon/Video");
      case "VolumeHigh":
      case "volumehigh":
        return import("@whitelabel-engine/ui/Icon/VolumeHigh");
      case "VolumeLow":
      case "volumelow":
        return import("@whitelabel-engine/ui/Icon/VolumeLow");
      case "VolumeMute":
      case "volumemute":
        return import("@whitelabel-engine/ui/Icon/VolumeMute");
      case "WalletAdd":
      case "walletadd":
        return import("@whitelabel-engine/ui/Icon/WalletAdd");
      case "WalletCheck":
      case "walletcheck":
        return import("@whitelabel-engine/ui/Icon/WalletCheck");
      case "WalletConnect":
      case "walletconnect":
        return import("@whitelabel-engine/ui/Icon/WalletConnect");
      case "WalletRemove":
      case "walletremove":
        return import("@whitelabel-engine/ui/Icon/WalletRemove");
      case "WarningFilled":
      case "warningfilled":
        return import("@whitelabel-engine/ui/Icon/WarningFilled");
      case "Web":
      case "web":
      case "Website":
      case "website":
        return import("@whitelabel-engine/ui/Icon/Web");
      case "Whitepaper":
      case "whitepaper":
      case "Paper":
      case "paper":
        return import("@whitelabel-engine/ui/Icon/Paper");
      case "World":
      case "world":
        return import("@whitelabel-engine/ui/Icon/World");
      case "Youtube":
      case "youtube":
        return import("@whitelabel-engine/ui/Icon/Youtube");
      default:
        return import("@whitelabel-engine/ui/Icon/XCircle");
    }
  });
};

const IconRender: FC<IconRenderProps> = (props) => {
  const [{ hash, LoadedComponent }, setLoadedComponent] = useState<any>({
    hash: null,
    LoadedComponent: null,
  });
  const {
    color = "primary",
    hoverColor: _hoverColor,
    className = "",
    alt = "",
    icon,
    ...rest
  } = props;
  const _hash = generateHash(props);

  const getComponent = () => {
    const hoverColor = _hoverColor ?? color;

    if (typeof icon === "string") {
      if (
        icon.startsWith("/") ||
        icon.startsWith("http") ||
        icon.startsWith("data:image") ||
        icon.startsWith("data:application")
      ) {
        return (
          <Image
            src={icon}
            alt={alt}
            // @ts-ignore
            width={32}
            className={className}
            height={32}
            {...rest}
          />
        );
      }

      const Icon = fetchLazyLoadedIcon(icon);

      return (
        <Icon
          color={color}
          hoverColor={hoverColor}
          className={className}
          {...rest}
        />
      );
    }

    // @ts-ignore
    const { viewBox, paths } = icon;

    return (
      <GenericIcon
        viewBox={viewBox}
        paths={paths}
        color={color}
        hoverColor={hoverColor}
        className={className}
        {...rest}
      />
    );
  };

  useEffect(() => {
    if (hash !== _hash) {
      return setLoadedComponent({
        hash: _hash,
        LoadedComponent: getComponent(),
      });
    }
  }, [_hash]);

  return LoadedComponent;
};

export default memo(IconRender);
