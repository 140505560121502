import crypto from "crypto";

export function generateHash(input: any): string {
  const hash = crypto.createHash("sha256");

  if (Array.isArray(input)) {
    hash.update(JSON.stringify(input));
  } else if (typeof input === "object") {
    hash.update(JSON.stringify(Object?.entries?.(input ?? {})?.sort?.()));
  } else {
    throw new Error("Input must be an array or an object");
  }

  return hash.digest("hex");
}
