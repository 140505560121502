import type {
  ButtonHTMLAttributes,
  ForwardedRef,
  MouseEvent,
  ReactNode,
} from "react";
import { forwardRef, useCallback, useMemo } from "react";
import { tv } from "tailwind-variants";

import type { Colors } from "../utils/colors";

export type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  variant?: "contained" | "outlined" | "ghost" | "text" | "icon";
  endIcon?: ReactNode | null;
  startIcon?: ReactNode | null;
  size?: "xsmall" | "small" | "medium" | "large";
  squared?: boolean;
  noShadow?: boolean;
  fullWidth?: boolean;
  disabled?: boolean;
  color?: Colors;
  skewed?: boolean;
};

const fontSize = {
  xsmall: "text-base",
  small: "text-lg",
  medium: "text-xl",
  large: "text-2xl",
};

const size = {
  xsmall: `px-6 py-1 ${fontSize.xsmall}`,
  small: `px-8 py-2 md:px-10 ${fontSize.small}`,
  medium: `px-10 py-4 md:px-12 ${fontSize.medium}`,
  large: `px-12 py-6 md:px-14 ${fontSize.large}`,
};

const baseButton = tv({
  base: [
    "duration-300",
    "ease-in-out",
    "flex",
    "gap-4",
    "items-center",
    "justify-center",
    "rounded-full",
  ],
  variants: {
    size: fontSize,
    disabled: {
      true: [
        "opacity-50",
        "hover:bg-unset",
        "hover:border-unset",
        "hover:text-unset",
        "cursor-not-allowed",
      ],
    },
    fullWidth: {
      true: "w-full",
    },
    noShadow: {
      true: ["shadow-none", "hover:shadow-none"],
    },
    squared: {
      true: ["rounded-none", "p-2", "h-full", "w-fit"],
    },
    leading: {
      true: "leading-6",
    },
    skewed: {
      true: ["rounded-none", "p-2", "h-full", "w-fit", "skew-x-12", "mx-2"],
    },
  },
});

const button = {
  contained: tv({
    extend: baseButton,
    base: ["border-2", "border-solid"],
    variants: {
      color: {
        primary:
          "border-primary bg-primary text-light hover:border-secondary hover:bg-secondary",
        secondary:
          "border-secondary bg-secondary text-light hover:border-primary hover:bg-primary",
        light:
          "border-secondary bg-light text-secondary hover:text-light hover:border-primary hover:bg-primary",
        danger:
          "border-danger bg-danger text-light hover:text-danger hover:border-danger hover:bg-light",
      },
      size,
    },
  }),
  outlined: tv({
    extend: baseButton,
    base: ["border-2", "border-solid"],
    variants: {
      color: {
        primary:
          "text-primary border-primary hover:text-light hover:bg-primary hover:border-primary bg-transparent",
        secondary:
          "text-secondary border-secondary hover:text-light hover:bg-secondary hover:border-secondary bg-transparent",
        light:
          "border-secondary bg-light text-secondary hover:text-light hover:border-primary hover:bg-primary",
        danger:
          "border-danger text-danger hover:text-light hover:border-danger hover:bg-danger bg-transparent",
      },
      size,
    },
  }),
  ghost: tv({
    extend: baseButton,
    base: ["border-none", "bg-transparent"],
    variants: {
      color: {
        primary: "text-primary hover:shadow-xl",
        secondary: "text-secondary hover:shadow-xl",
        light: "text-light hover:shadow-xl",
        danger: "text-danger hover:shadow-xl",
      },
      size,
    },
  }),
  icon: tv({
    extend: baseButton,
    base: ["border-2", "p-2", "h-fit", "w-fit"],
    variants: {
      color: {
        primary:
          "border-primary bg-primary text-light hover:border-secondary hover:bg-secondary",
        secondary:
          "border-secondary bg-secondary text-light hover:border-primary hover:bg-primary",
        light:
          "border-secondary bg-light text-secondary hover:text-light hover:border-primary hover:bg-primary",
        danger:
          "border-danger bg-danger text-light hover:text-danger hover:border-danger hover:bg-light",
      },
      disabled: {
        false: "group",
      },
    },
  }),
  text: tv({
    extend: baseButton,
    base: ["p-0"],
  }),
};

export const Button = forwardRef(function Button(
  {
    children,
    variant = "contained",
    endIcon = null,
    startIcon = null,
    size = "small",
    fullWidth = false,
    squared = false,
    noShadow = false,
    disabled = false,
    color = "primary",
    className = "",
    type = "button",
    skewed = false,
    ...props
  }: ButtonProps,
  ref: ForwardedRef<HTMLButtonElement>,
) {
  const buttonClassNames = useMemo(() => {
    if (variant === "text") {
      return button.text({
        className: `text-${color} ${className}`,
        disabled,
        size,
        leading: true,
      });
    }

    return button[variant!]({
      // @ts-expect-error
      color,
      size,
      className,
      fullWidth,
      squared,
      noShadow,
      disabled,
      leading: true,
      skewed,
    });
  }, [
    variant,
    color,
    disabled,
    className,
    squared,
    fullWidth,
    noShadow,
    size,
    skewed,
  ]);

  const handleOnClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      if (disabled) return;
      props?.onClick?.(event);
    },
    [disabled, props?.onClick],
  );

  return (
    <button
      ref={ref}
      className={buttonClassNames}
      disabled={disabled}
      type={type}
      {...props}
      onClick={handleOnClick}
    >
      {startIcon}
      {children}
      {endIcon}
    </button>
  );
});

export default Button;
