import type { FC } from "react";
import React from "react";

import _BlankIcon from "./_Blank";
import type { IconProps as _Iconprops } from "./utils";

type IconProps = Omit<_Iconprops, "width"> & {
  viewBox?: string;
  paths?: string[];
};

const GenericIcon: FC<IconProps> = ({ viewBox, paths, ...props }) => {
  return (
    <_BlankIcon viewBox={viewBox} {...props}>
      {paths?.map((path) => <path d={path} key={path} />)}
    </_BlankIcon>
  );
};

export default GenericIcon;
