"use client";
import type { FeatureProps } from "@whitelabel-engine/typings/configuration";
import type { FC, PropsWithChildren } from "react";
import { createContext, useContext } from "react";

// Define the shape of the global state
type DynamicConfigProviderContextProps = {
  features: FeatureProps;
};

// Create a new context for the global state
export const DynamicConfigProviderContext = createContext<FeatureProps>({
  "@whitelabel-engine/ui/Image": "next/image",
});

export const useDynamicConfigProvider = () =>
  useContext(DynamicConfigProviderContext);

// Define a provider component that wraps its children with the global state
const DynamicConfigProvider: FC<
  PropsWithChildren<DynamicConfigProviderContextProps>
> = ({ children, features }) => {
  // Wrap the children with the global state context provider
  return (
    <DynamicConfigProviderContext.Provider value={features}>
      {children}
    </DynamicConfigProviderContext.Provider>
  );
};

export default DynamicConfigProvider;
