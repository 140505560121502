import type { FC, PropsWithChildren } from "react";

import type { IconProps } from "./utils";

const _BlankIcon: FC<PropsWithChildren<IconProps>> = ({
  hoverColor,
  className = "",
  color = "primary",
  width = 24,
  viewBox,
  style = {},
  children,
  ...props
}) => {
  const iconClassName = [
    "cursor-pointer",
    `fill-${color}`,
    `group-hover:fill-${hoverColor ?? color}`,
    `hover:fill-${hoverColor ?? color}`,
    className,
  ].join(" ");
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={iconClassName}
      width={width}
      height={width}
      viewBox={viewBox}
      style={{
        fillRule: "evenodd",
        ...style,
      }}
      {...props}
    >
      {children}
    </svg>
  );
};

export default _BlankIcon;
